<template>
    <div class="wrapper" :class="{opened: open}">
        <div class="select-toggle" @click="toggleSelect()">
            {{ this.options[selectedIndex] || '---' }}
        </div>
        <div class="click-outside" v-if="this.open" @click="close()"></div>
        <div class="option-list" v-if="this.open">
            <div class="option-item" v-for="(option, optionIndex) of this.options" :key="optionIndex" @click="selectItem(option, optionIndex)">
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'select-input',
        props: ['options', 'value'],
        data: function() {
            return {
                open: false,
                selectedIndex: null,
                selectedValue: null
            }
        },
        methods: {
            updateValue: function() {
                this.$emit('input', this.selectedValue);
            },
            toggleSelect: function() {
                this.open = !this.open;
            },
            selectItem(selectedValue, selectedIndex) {
                this.selectedValue = selectedValue;
                this.selectedIndex = selectedIndex

                this.updateValue();
                this.toggleSelect();
            },
            close() {
                this.open = false;
            }
        },
        mounted() {
            this.selectedValue = this.value;
            
            if (this.selectedValue && this.options) {
                this.selectedIndex = this.options.indexOf(this.selectedValue);
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        display: inline-block;
        color: var(--text);
        font-size: 1.375rem;
        font-family: var(--font-text);
        padding-left: 5px;
    }

    .select-toggle {
        width: 150px;
        border-bottom: 2px solid var(--accent);
        padding-left: 5px;
        cursor: pointer;
    }

    .option-list {
        margin-top: -2px;
        padding: 5px;
        position: absolute;
        width: 150px;
        background-color: var(--background);
        border: 2px solid var(--accent);
    }

    .option-item {
        line-height: 2.5rem;
        border-left: 1px dotted var(--text);
        border-right: 1px dotted var(--text);
        border-bottom: 1px dotted var(--text);
        cursor: pointer;
        padding: 5px;
        text-align: center;
        background-color: var(--primary);
    }

    .option-item:first-child {
        border-top: 1px dotted var(--text);
    }

    .option-item:hover, .option-item:active {
        background-color: var(--accent);
        color: var(--primary);
    }

    .wrapper::before {
        content: url('data:image/svg+xml; utf8, <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 444.819 444.819" style="enable-background:new 0 0 444.819 444.819;" xml:space="preserve"><g><path fill="%23080808" d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693C444.819,130.287,441.295,121.629,434.252,114.203z"/></g></svg>');
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        padding-left: 125px;
        pointer-events: none;
    }

    .wrapper::before, .select-toggle {
        line-height: 1.875rem;
    }

    .wrapper.opened::before {
        transform: rotate(180deg);
        transform-origin: 95% 100%;
    }

    .click-outside {
        position: fixed;
        top: 0;
        left: 0;
        width: 100dvw;
        height: 100dvh;
        overflow: clip;
        background-color: #ffffff00;
    }

    @media (max-width: 920px) {
        .wrapper::before, .select-toggle {
            line-height: 1.6875rem;
        }

        .wrapper.opened::before {
            transform: rotate(180deg);
            transform-origin: 95% 85%;
        }
    }

     @media (max-width: 840px) {
        .option-item {
            line-height: 3.125rem;
        }
     }
</style>
