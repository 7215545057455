<template>
    <div>
        <h2>Archívum</h2>

        <div class="framed-content">
            <div class="selection-header">
                <p class="select-label">Válassz évszámot:</p>
                <select-input :options="[2023, 2022, 2021, 2020, 2019]" v-model="year"></select-input>
            </div>

            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import SelectInputComponent from './subcomponents/SelectInputComponent.vue'

    export default {
      name: 'archive',
      components: {
        'select-input': SelectInputComponent
      },
      data: function() {
        const { fullPath } = this.$router.history.current;
        const year = fullPath.split('/')[2];

        return {
          year:  +year
        }
      },
      created() {
        if (!this.year) {
          this.year = 2023;
        }
      },
      watch: {
        year: function () {
          // eslint-disable-next-line no-console
          console.log(this.$router);
          this.$router.push(`/archive/${this.year}`);
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .framed-content {
        border: 2px dotted var(--accent);
        padding: 20px;
        margin-top: 20px;
    }

    .framed-content > select {
        margin-bottom: 20px;
    }

    .selection-header {
        display: flex;
    }

    .select-label {
        margin-right: 5px;
        line-height: 1.75rem;
    }

    @media (max-width: 920px) {
        .select-label {
            line-height: 1.5rem;
        }
    }
</style>
